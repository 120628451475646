import { captureException, withScope } from '@sentry/solidstart';
import { breadcrumbs } from './breadcrumb-list';
import { image } from './image';
import { webpage } from './webpage';
import { getSchemaString } from './schema';
import { getUrl } from './base-url';
import type { PostalAddress, SportsActivityLocation, Thing } from 'schema-dts';
import type { Facility } from '../../graphql';

export function getFacilitySchema(facility?: Facility) {
	if (!facility) {
		return '';
	}

	const things: Array<Thing> = [
		breadcrumbs(`/course/${facility.slug}`, [
			{ name: 'Home', pathname: '/' },
			{ name: 'Courses', pathname: '/courses' },
			{ name: facility.name, pathname: `/course/${facility.slug}` },
		]),
		webpage(`/course/${facility.slug}`, {
			title: `${facility.name} | Troon Rewards | Book tee times`,
			description: facility.metadata?.description?.slice(0, 154),
		}),
	];

	withScope((scope) => {
		scope.setLevel('warning');
		scope.setExtra('facilityName', facility.name);
		scope.setExtra('facilityId', facility.id);

		let hero: Thing | null = null;

		if (!facility.metadata?.hero?.url) {
			captureException(new Error('Missing facility hero image'));
		} else {
			hero = image(facility.metadata.hero.url);
			things.push(hero);
		}

		const location: SportsActivityLocation = {
			'@type': 'SportsActivityLocation',
			'@id': getUrl(`/course/${facility.slug}`, 'facility'),
			name: facility.name,
			image: [...(hero ? [hero] : []), ...(facility.metadata?.gallery.map((img) => image(img.url!)) ?? [])],
			telephone: facility.metadata?.phone ?? '',
			currenciesAccepted: 'USD',
			geo: {
				'@type': 'GeoCoordinates',
				latitude: facility.latitude!,
				longitude: facility.longitude!,
			},
		};

		if (!facility.metadata?.address) {
			captureException(new Error('Missing facility address'));
		} else {
			location.address = {
				'@type': 'PostalAddress',
				streetAddress: facility.metadata.address.street ?? '',
				addressLocality: facility.metadata.address.city ?? '',
				addressRegion: facility.metadata.address.state ?? '',
				addressCountry: facility.metadata.address.country ?? '',
				postalCode: facility.metadata.address.postalCode ?? '',
			} satisfies PostalAddress;
		}

		things.push(location);
	});

	return getSchemaString(things);
}
